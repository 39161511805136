/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';
@use '@angular/material' as mat;
@include mat.core();
@import "@angular/material/theming";
@import "../node_modules/@angular/material/core/theming/palette";
@import url("../node_modules/@angular/material/prebuilt-themes/indigo-pink.css");
@import url("../node_modules/ngx-toastr/toastr.css");
@import url("../node_modules/ngx-spinner/animations/ball-spin.css");
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

/* For use in src/lib/core/theming/_palette.scss */
$mcfinnetpalette: (
    50 : #e0e9f2,
    100 : #b3c8de,
    200 : #80a4c8,
    300 : #4d7fb1,
    400 : #2663a1,
    500 : #004890,
    600 : #004188,
    700 : #00387d,
    800 : #003073,
    900 : #002161,
    A100 : #91acff,
    A200 : #5e86ff,
    A400 : #2b60ff,
    A700 : #124cff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


// Birincil renk tanımı
$my-primary: mat-palette($mcfinnetpalette,500);
$my-accent: mat-palette($mat-blue);
$my-warn: mat-palette($mat-red,A700);
//Tema tanımı
$my-theme: mat.define-light-theme((
color: (
  primary: $my-primary,
  accent: $my-accent,
  warn: $my-warn
),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.all-component-themes($my-theme);


.mat-elevation-z8{
  background-color: white;
  border-radius: 15px;
}

.modal-header {
    position: relative;
    height: auto;
    background-color: #0a2d6d;;
    color:#fff;
  }

  .modal-title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin: 0;
  }

  // .modal-body{
  //   background-color:#dae2e9;
  // }

  td{
    text-align: center;
  }

  table {
    width: 100%;
  }

  mat-mdc-cell mdc-data-table__cell cdk-cell{
    text-align: center;
  }
  .mat-mdc-form-field {
    font-size: 13px;
    width: 100%;
  }
  .mat-mdc-form-field {
    font-size: 13px !important;
    width: 100%;
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix
  {
    width: 100%;
    height: 25px !important;
  }
  .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label
  {
    font-size: 14px !important;

  }
 .mdc-data-table__header-cell {
  background-color: #0a2d6d !important;
  color: #fff;
 }

 .mdc-data-table__header-cell:hover {
  color: #fff;
 }

 .main-sidebar{
  background-color: #0a2d6d;
 }

 .d-block{
  color:#000000
 }

 .card-header {
  background-color: #0a2d6d;
  color: white;
  display: flex;
  justify-content: center;
}
#active-share-table
{
  cursor:default;
}
.mat-elevation-z8 {
  overflow-x: auto;
  margin-top: 20px;
  cursor: pointer;
  /* Diğer stil kuralları */
}
#_closeButton
{
  color: #fff
}
::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dfdcdc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bab9b9;
}
.mat-elevation-z8
{
  margin-top: 0 !important;
}
@media (min-width: 400px) and (max-width: 600px) {
  .modal-content {
    min-width: 400px;
  }
}
@media (min-width: 600px) and (max-width: 800px) {
  .modal-content {
    min-width: 600px;
  }
}
@media (min-width: 800px) and (max-width: 1020px) {
  .modal-content {
    min-width: 800px;
  }
}
@media (min-width: 1020px) and (max-width: 1200px) {
  .modal-content {
    min-width: 1000px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .modal-content {
    min-width: 1200px;
  }
}
@media (min-width: 1400px) and (max-width: 1500px) {
  .modal-content {
    min-width: 1300px;
  }
}
@media (min-width: 1500px) and (max-width: 1700px) {
  .modal-content {
    min-width: 1500px;
  }
}
@media (min-width: 1700px) and (max-width: 1800px) {
  .modal-content {
    min-width: 1650px;
  }
}
@media (min-width: 1800px) and (max-width: 1900px) {
  .modal-content {
    min-width: 1750px;
  }
}
@media (min-width: 1900px) and (max-width: 2495px) {
  .modal-content {
    min-width: 1850px;
  }
}
.modal-dialog {
  display: flex;
  justify-content: center;
}
span
{
  font-family: 'Source Sans Pro';
}
